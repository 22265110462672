import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

interface JwtPayload {
  exp: number;
}

@Injectable({
  providedIn: 'root',
})
export class JwtHelperService {
  constructor(private readonly logger: NGXLogger) {}

  public isTokenExpired(token: string): boolean {
    if (!token) {
      return true;
    }

    try {
      const payload = JSON.parse(atob(token.split('.')[1])) as JwtPayload;
      const currentTime = Math.floor(Date.now() / 1000);
      return payload.exp < currentTime;
    } catch (error) {
      this.logger.error(error);
      return true;
    }
  }
}
