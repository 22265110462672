import {
  HttpClient,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { TitleStrategy, provideRouter } from '@angular/router';
import { provideTransloco } from '@jsverse/transloco';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { MARKED_OPTIONS, MarkdownModule } from 'ngx-markdown';
import { environment } from 'src/environments/environment';
import { BASE_PATH as BACKEND_BASE_PATH } from '../core/backend';
import { BASE_PATH as INC_ASSESSMENT_SERVICE_BASE_PATH } from '../core/inc-assessment-service';
import { jwtInterceptorProvider } from '../core/interceptors';
import { CustomTitleStrategy } from '../core/services';
import { TranslocoHttpLoader } from '../transloco-loader';
import { appRoutes } from './routes/app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes),
    provideClientHydration(),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    jwtInterceptorProvider,
    provideTransloco({
      config: {
        availableLangs: ['en', 'de'],
        defaultLang: 'en',
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideCharts(withDefaultRegisterables()),
    importProvidersFrom(
      LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG }),
      MarkdownModule.forRoot({
        loader: HttpClient,
        markedOptions: {
          provide: MARKED_OPTIONS,
          useValue: {
            gfm: true,
            breaks: true,
            pedantic: true,
          },
        },
      }),
    ),
    {
      provide: TitleStrategy,
      useClass: CustomTitleStrategy,
    },
    {
      provide: BACKEND_BASE_PATH,
      useValue: environment.backendUrl,
    },
    {
      provide: INC_ASSESSMENT_SERVICE_BASE_PATH,
      useValue: environment.incAssessmentServiceUrl,
    },
  ],
};
