import { NgClass, NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  standalone: true,
  imports: [NgIf, NgClass],
})
export class DropdownComponent {
  @Input() dropdownClass: string = '';
  @Input() buttonClass: string = '';
  @Input() buttonLabel: string | undefined;
  @Input() contentClass: string = '';

  @ViewChild('dropdownButtonElement') dropdownButtonElement!: ElementRef;
  @ViewChild('dropdownContentElement') dropdownContentElement!: ElementRef;

  showDropdown = false;

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  closeDropdown() {
    this.showDropdown = false;
  }

  get ngDropdownClass() {
    return `dropdown ${this.dropdownClass}`;
  }

  get ngButtonClass() {
    return `${this.buttonClass}`;
  }

  get ngContentClass() {
    return `dropdown-content ${this.contentClass} ${!this.showDropdown ? 'hidden' : ''}`;
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    const targetElement = event.target as HTMLElement;
    if (
      !this.dropdownButtonElement.nativeElement.contains(targetElement) &&
      !this.dropdownContentElement.nativeElement.contains(targetElement)
    ) {
      this.showDropdown = false;
    }
  }
}
